import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import IntuChargeLogoDark from "../../Icons/IntuChargeLogoDark";
import Button from "../CommonSection/Buttons/Button";
import Input from "../CommonSection/Input/Input";
import Footer from "./Footer/Footer";
import classes from "./Login.module.scss";

const Login: React.FC = (): JSX.Element => {
  const router = useRouter();

  return (
    <div className={`${classes.login_section}`}>
      <div className={classes.login_logo}>
        <IntuChargeLogoDark />
      </div>
      <div className={`${classes.login_container}`}>
        <div className={classes.login_page_heading}>
          <div className={`${classes.login_page_title}`}>
            Welcome to IntuCharge
          </div>
          <span className={classes.login_page_user}>Super Admin</span>
        </div>
        <div className={`${classes.login_box}`}>
          <div className="w-full grid pl-24">
            <Image
              src="/image/loginimg.png"
              alt="Image"
              layout="responsive"
              width={904}
              height={417}
              objectFit="contain"
            />
          </div>
          <div className={`${classes.login_separates}`}>
            <div className={`${classes.login_card}`}>
              <h1 className={`${classes.login_title} font-display`}>
                Login To Your Account
              </h1>

              <h3 className={`${classes.login_section_text}`}>
                Add your registered email and password
              </h3>

              <div className={`${classes.login_form_separates}`}>
                <div className={`${classes.login_details_separates}`}>
                  <Input id="email" label="Email Address" type="text" />
                  <Input id="password" label="Password" type="Password" />
                  <div className={`${classes.login_pw}`}>
                    <Link href="/forgot-password">Forgot Password?</Link>
                  </div>
                </div>

                <div className="mt-0">
                  <Button
                    className="w-[268px] lg:h-[47px]"
                    variant="primary"
                    onClick={() => {
                      router.push("/");
                    }}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
